#pdfQustn{
    width: 100%;
    height: 600px;
}

.cursorPointer{
    cursor: pointer; 
}
.cursorPointer:hover{
    margin-top: 5px;
    transition: 0.3s;
}

.answerFile{
    margin-bottom: 5px;
    width: 95%;
}

.fileGroup{
    display: -webkit-box;
}

.fileRemove{
    margin-left: 5px;
    margin-top: 8px;
    cursor: pointer;
}

.displayFlex{
    display: flex;
}

.questionHeader {
    cursor: 'pointer' !important;
    user-select: 'none' !important;
}