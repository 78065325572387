#main {
    display: flex;
    min-height: 100%;
    width: 100%;
    position: absolute;
}
.main-row {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    margin-left: 0;
    margin-right: 0;
    flex-direction: row-reverse;
    background:linear-gradient(45deg, rgba(0, 123, 255, 0.85), rgba(0, 65, 136, 0.85)), url('https://hcet.campusgenie.co.in/img/background1.jpg') center top no-repeat;
    background-size: cover;
}
.main-col1{ 
    color: white;overflow: hidden;position: relative;
    align-items: center;display: flex;flex: 1;justify-content: center;
} 
.college-logo{
    overflow: auto;
}
.col1-width {
    max-width: 440px;
}
.col1-img {
    margin-bottom: 100px;
}
.col1-img img{
    width: 300px;
}
.col1-img h1{
    color: white;
    font-size: larger;
}

.leftLogin{
    min-height: 100vh;
    background-color: #f7fafc;
}
.box-shadow-0{
    box-shadow: none;
}

footer {
    background-color: white;
    position: fixed;
    width: 100%;
    bottom: 0px;
    border-top: 1px solid #e6ecf0;
    padding: 11px 0;
    z-index: 2;
}
footer ul {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: auto;
    padding: 0;
    list-style: none;
    margin-bottom: 0;
}
footer ul li {
    display: inline-block;
    padding: 0 9px 0 9px;
    font-size: smaller;
}
footer ul li a {
    color: #aab8c2;
    text-decoration: none;
}
footer .fa-heart{
  color: #007bff;  
}
.portal-avatar{
    width:70px;
}
.img-responsive{
    width: 100%;
}

.help-block{
    color: red;
    font-size: 14px;
}