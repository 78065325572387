/* Blank Page */
#blank-page h1{
    font-size: 100px;
}

#blank-page .mt-10{
    margin-top: 10%;
}

.copyRight{
    position: absolute;
    bottom: 0;
    width: 100%;
}
/* Common */
.cursor{
  cursor: pointer;
}
.cursor-hover{
  cursor: pointer; 
}
.cursor-hover:hover{
  cursor: pointer;
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
}

/* Loader */
.loader-block{
    display: block;
    position: relative;
    text-align: -webkit-center; 
}
.loader {
    border: 5px solid #e5e5e5; 
    border-radius: 50%; 
    border-top: 5px solid #5d71e3; 
    width: 35px; 
    height: 35px; 
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* Margins */
.mt-15{
  margin-top: 15px;
} 
.mb-15{
  margin-bottom: 15px;
}
.mb-2{
  margin-bottom: 2px;
}

/* padding */
.pt-10per{
  padding-top: 10%;
}
.pl-0{
  padding-left: 0px;
} 
.p-2{
  padding: 2px;
}

/* Icons */
.icon2 {
  width: 2rem;
  height: 2rem;
}
.ni-1rem{
  font-size: 1rem !important;
}
/* Display */
.display-block{
  display: block;
}

/* Width */
.w10{
  width: 10%;
}
.w20{
  width: 20%;
}
.w30{
  width: 30%;
}
.w40{
  width: 40%;
}
.w50{
  width: 50%;
}
.w60{
  width: 60%;
}
.w70{
  width: 70%;
}
.w80{
  width: 80%;
}
.w90{
  width: 90%;
}
.w100{
  width: 100%;
}

/* Font */
.font-size-medium{
  font-size: medium;
}
.font-weight-bold{
  font-weight: bold;
}